/// <reference path="../typings/tsd.d.ts"/>
'use strict';

var app = angular.module(
	'sspoc',
	// Dependency list;
	[
	'ui.router',
	'app.find',
	'ngAnimate',
	'ui.bootstrap',
	'ncy-angular-breadcrumb',
	'ngDropdowns',
	'ngTagsInput',
	'ngCookies',
	'app.results',
	'app.details'
	]);

// Create a factory for managing shared data;
app.factory(
  'Data'
  , function() {
				var textToUse = "";

				this.setText = function(inputText) {
			textToUse = inputText
				}

				this.getText = function() {
			return textToUse;
				}
		}
);

// Create a factory for managing cross-controller messages;
app.factory(
	'switchBoard',
	['$cookies',
		function($cookies) {

			// -------------------------------------------------;
			// Store user preferred language;
			// -------------------------------------------------;
			var setUserLanguage = function(aUserLanguage) {
				$cookies.put('userLanguage', aUserLanguage);
			};

			var getUserLanguage = function() {
				// Try service variable first; if variable
				// not found, check cookie;
				var usrLang = undefined;

				if (!_.isUndefined($cookies.get('userLanguage'))) {
					usrLang = $cookies.get('userLanguage');
				}

				return usrLang;
			}

			// -------------------------------------------------;
			// Store find criteria in cookie to keep
			// data if user reloads page;
			// -------------------------------------------------;
			// Type of primary collection in which to begin
			// search (companies or contacts);
			var setFindType = function(aFindType) {
				$cookies.put('findType', aFindType);
			};

			var getFindType = function() {
				var ft = undefined;

				if (!_.isUndefined($cookies.get('findType'))) {
					ft = $cookies.get('findType');
				}

				return ft;
			}

			// User-entered text as search parameter;
			var setFindText = function(aFindText) {
				$cookies.put('findText', aFindText);
			};

			var getFindText = function() {
				var ft = undefined;

				// Try cookie;
				if (!_.isUndefined($cookies.get('findText'))) {
					ft = $cookies.get('findText');
				}

				return ft;
			}

			// Country selection parameter;
			var setFindCountry = function(aFindCountry) {
				$cookies.put('findCountry', aFindCountry);
			};

			var getFindCountry = function() {
				var fc = undefined;

				if (!_.isUndefined($cookies.get('findCountry'))) {
					fc = $cookies.get('findCountry');
				}

				return fc;
			}

			// Return formatted value for find type;
			var getTypeText = function() {
				return ($cookies.get('findType') === 'Company') ? 'companies' : 'contacts';
			}

			return {
				// Global language;
				setUserLanguage: setUserLanguage,
				getUserLanguage: getUserLanguage,

				// Search criteria;
				setFindType: setFindType,
				getFindType: getFindType,

				setFindText: setFindText,
				getFindText: getFindText,

				setFindCountry: setFindCountry,
				getFindCountry: getFindCountry,

				// Display management;
				getTypeText: getTypeText
			};

		}]);

// Set application routing;
app.config(
  function ($stateProvider, $urlRouterProvider, $breadcrumbProvider) {
    $breadcrumbProvider.setOptions({
      templateUrl: 'templates/shared/ui-breadcrumbs.tpl.html'
    });
    $stateProvider
      .state('home', {
        url: '/home',
				views:
					{
					header:
						{
						templateUrl: 'templates/find/hdr-find.html',
						controller:  'findController'
						},
					content:
						{
						templateUrl: 'templates/find/main-find.html',
						controller:  'findController'
						}
					},
        ncyBreadcrumb: {
          label: 'Home'
        }
      })
      .state('results', {
        url: '/results',
				views:
					{
					header:
						{
						templateUrl: 'templates/partials/hdr.html'
						},
					content:
						{
						templateUrl: 'templates/results/results.html',
						controller:  'resultsController'
						},
					ncyBreadcrumb: {
						label: 'Results'
						}
					},
        ncyBreadcrumb: {
          label: 'Results',
					parent: 'home'
        }
      })
			.state('user.settings.one', {
				url: '/one',
				template: '<div>Settings nested route 1</div>'
			}).state('user.settings.two', {
				url: '/two',
				template: '<div>Settings nested route 2</div>'
			});
      ;

    $urlRouterProvider.otherwise('/home');

  }
);

app.run(function($rootScope, $state, $breadcrumb) {
    $rootScope.isActive = function(stateName) {
      return $state.includes(stateName);
    }
    $rootScope.getLastStepLabel = function() {
      return 'Angular-Breadcrumb';
    }
  });

// Define new template for country dropdown;
app.run(
	function ($templateCache) {
		$templateCache.put('ngDropdowns/templates/dropdownSelect.html', [
			'<div ng-class="{\'disabled\': dropdownDisabled}" class="wrap-dd-select" tabindex="0">',
				'<div class="vert-center-wrapper">',
					'<div class="vert-center selected">',
						'<i class="glyph-cdd {{dropdownModel.cssClass}}"></i>',
					'</div>',
				'</div>',
				'<ul class="dropdown">',
					'<li ng-repeat="item in dropdownSelect"',
						' class="dropdown-item"',
						' dropdown-select-item="item"',
						' dropdown-item-label="labelField">',
					'</li>',
				'</ul>',
			'</div>'
		].join(''));

		$templateCache.put('ngDropdowns/templates/dropdownSelectItem.html', [
			'<li ng-class="{divider: (dropdownSelectItem.divider && !dropdownSelectItem[dropdownItemLabel]), \'divider-label\': (dropdownSelectItem.divider && dropdownSelectItem[dropdownItemLabel])}">',
				'<a href="" class="dropdown-item"',
					' ng-if="!dropdownSelectItem.divider"',
					' ng-href="{{dropdownSelectItem.href}}"',
					' ng-click="selectItem()">',
						'<i class="glyph-cdd {{dropdownSelectItem.cssClass}}"></i>',
					'{{dropdownSelectItem[dropdownItemLabel]}}',
				'</a>',
				'<span ng-if="dropdownSelectItem.divider">',
					'{{dropdownSelectItem[dropdownItemLabel]}}',
				'</span>',
			'</li>'
		].join(''));
});

app.service(
	'dbRequest',
	function($http, $q) {
		delete $http.defaults.headers.common['X-Requested-With'];
		this.getData = function(aQuery) {
			var auth = 'Basic ' + btoa('neo4j:mnbv!456')

	    // $http() returns a $promise that we can add handlers with .then()
			return $http({
				method: 'POST',
				url: 'http://jllcontactnet.cloudapp.net:7474/db/data/transaction/commit',
				headers: {
				    'Authorization': auth
				},
				data: JSON.stringify(aQuery)
			});
	}
});